import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/layout'

import {responsiveTitle1} from '../components/typography.module.css'
import HeroBlog from '../components/hero/HeroBlog'

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title {
            _key
            _type
            en
            de
          }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      mainImage {
      asset {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      }
      seperatorImage {
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

  }
`

const BlogPage = props => {
  const {data, errors, language, pageContext} = props
  const site = (data || {}).site
  const menu = (data || {}).menu
  const socialMedia = (data || {}).socialMedia
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage>
      <SEO title='Blog' />
      <HeroBlog fluid={site.mainImage.asset.fluid} />
      <Container>
        <div className='blog--wrapper'>
          <h1 className={responsiveTitle1}>Blog</h1>
          {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} language={language} />}
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPage
